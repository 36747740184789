var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex justify-start align-center flex-column pa-0",attrs:{"fluid":"","fill-height":""}},[_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('img',{attrs:{"src":require('@/assets/logo.svg'),"width":"180","height":"88"}})])],1),_c('v-row',{staticClass:"mt-1 mb-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h2',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.subTitle))])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-1 pa-5 mb-3 rounded-xl ",class:_vm.$style.form,attrs:{"color":"#fff","rounded":""}},[_c('v-card-text',{class:_vm.$style.form_text},[_c('h2',{class:_vm.$style.form_msg},[_vm._v("JRE IDへのアカウント移行"),_c('br'),_vm._v("はこちら")]),_c('base-btn',{style:({
              color: 'white',
              fontSize: '1rem',
              boxShadow: 'none'
            }),attrs:{"color":'#72AD47',"label":_vm.jreidSwitchButton},on:{"click":_vm.onMigrationPage}}),_c('div',{staticClass:"py-3"}),_c('h2',{class:_vm.$style.form_msg},[_vm._v("JaM idマイページはこちら")]),_c('base-btn',{style:({
              color: 'white',
              fontSize: '1rem',
              boxShadow: 'none'
            }),attrs:{"label":_vm.jamIdMyPageButton},on:{"click":_vm.onRedirect}}),_c('div',{staticClass:"pb-12"})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }