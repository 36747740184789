<template>
  <v-container fluid fill-height class="d-flex justify-start align-center flex-column pa-0">
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <img :src="require('@/assets/logo.svg')" width="180" height="88" />
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-0">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ subTitle }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-card color="#fff" class="py-1 pa-5 mb-3 rounded-xl " rounded :class="$style.form">
            <v-card-text :class="$style.form_text">
              <h2 :class="$style.form_msg">JRE IDへのアカウント移行<br />はこちら</h2>
              <base-btn :color="'#72AD47'" :label="jreidSwitchButton" @click="onMigrationPage" :style="{
                color: 'white',
                fontSize: '1rem',
                boxShadow: 'none'
              }" />
              <div class="py-3"></div>
              <h2 :class="$style.form_msg">JaM idマイページはこちら</h2>
              <base-btn :label="jamIdMyPageButton" @click="onRedirect" :style="{
                color: 'white',
                fontSize: '1rem',
                boxShadow: 'none'
              }" />
              <div class="pb-12">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn class="text-center" :class="$style.back" color="#FD6955" text @click="goHome">
            HOMEに戻る (Home)
          </v-btn>
        </v-col>
      </v-row> -->
    </div>
  </v-container>
</template>


<script>
export default {
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      subTitle: 'アカウント切替画面',
      firstTitle: 'JRE IDへのアカウント移行\n\rはこちら',
      secondTitle: 'JaM idマイページはこちら',
      jreidSwitchButton: 'JRE ID 切替',
      jamIdMyPageButton: 'JaM id マイページ'
    };
  },
  methods: {
    onRedirect() {
      this.$router.push({ name: 'mypage-index' })
    },
    onMigrationPage() {
      this.$router.push({ name: 'migration-start' })
    }
  },
}
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form_msg {
  font-size: 16px !important;
  color: #373e42;
  font-weight: 600;
  text-align: center !important;
  margin: 20px 0px;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}
</style>
